import React from "react"

const PageHeadingTitle = data => {
  return (
    <>
      <p
        className="font-weight-bold text-uppercase text-primary d-inline-block border-bottom"
        style={{
          fontSize: "37px",
          fontFamily: "Roboto, serif",
          lineHeight: "64.75px",
          margin: 0,
        }}
      >
        {data.h1}
      </p>
      <h1 style={{ fontFamily: "ITC Korinna Bold,serif", fontSize: "52.5px" }}>
        {data.h2}
      </h1>
    </>
  )
}

export default PageHeadingTitle

import React from "react"
import Layout from "@components/layout"
import { graphql, Link } from "gatsby"
import SEO from "@components/seo"
import PageHeadingTitle from "@components/pageHeadingTitle"
const slugify = require("slugify")

const Blogs = ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <SEO
        title="Blog & Information Center | Fabricating Equipment Sales"
        description="Stay informed with the latest insights on fabrication, automation, cutting, sawing, milling, and more. Explore expert tips and industry trends in our blog."
      />
      <div className="page-headline">
        <div className="container">
          <PageHeadingTitle h1={"Blog"} h2={"Information Center"} />
        </div>
      </div>
      <div className="page-body">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-8">
              <h2 className="font-weight-bold text-primary mb-5 h4">
                Latest Posts
              </h2>
              <div className="row">
                {data.allSanityPost.edges.map(blog => (
                  <div
                    className="col-md-6 mb-5 text-left"
                    key={blog.node.slug.current}
                  >
                    <div className="blog-item bg-light rounded border">
                      <Link to={`/` + blog.node.slug.current}>
                        <div className="blog-img-tile">
                          <div className="tile-bg-wrapper">
                            <span className="doc-icon">
                              <i className="fa fa-file-text"></i>
                            </span>
                            <div
                              className="tile-bg rounded-top"
                              style={{
                                backgroundImage: `url(${
                                  blog.node.mainImage !== null
                                    ? blog.node.mainImage.asset.fluid.src
                                    : require("@images/no-image-placeholder.jpg")
                                })`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </Link>
                      <div className="my-3 px-3">
                        <span className="text-uppercase font-weight-bold small d-block">
                          {blog.node.publishedAt}
                        </span>
                        <Link to={`/` + blog.node.slug.current} className="h4">
                          {blog.node.title}
                        </Link>
                        {blog &&
                        blog.node &&
                        blog.node.categories &&
                        blog.node.categories.length !== 0 ? (
                          <ul className="list-inline small m-0">
                            {blog.node.categories.map(ct => (
                              <li
                                className="list-inline-item my-1"
                                key={ct.title}
                              >
                                <Link
                                  to="/"
                                  cclassName="small px-2 py-1 bg-primary text-white rounded-sm"
                                >
                                  {ct.title}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        ) : null}
                        {/*placeholder categories - REMOVE LATER*/}
                        <ul className="list-inline small m-0">
                          <li className="list-inline-item my-1">
                            <Link
                              to="/"
                              className="small px-2 py-1 bg-primary text-white rounded-sm"
                            >
                              Automation
                            </Link>
                          </li>
                          <li className="list-inline-item my-1">
                            <Link
                              to="/"
                              className="small px-2 py-1 bg-primary text-white rounded-sm"
                            >
                              Beam
                            </Link>
                          </li>
                          <li className="list-inline-item my-1">
                            <Link
                              to="/"
                              className="small px-2 py-1 bg-primary text-white rounded-sm"
                            >
                              Coping
                            </Link>
                          </li>
                        </ul>
                        <p className="small mt-2 mb-0">{blog.node.excerpt}</p>
                      </div>
                      <Link
                        to={`/` + blog.node.slug.current}
                        className="btn btn-secondary btn-secondary-red btn-sm m-3"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-4 mb-4 pl-md-5">
              <div className="side-content">
                <h2 className="font-weight-bold text-primary mb-5 h4">
                  Categories
                </h2>
                <ul className="list-inline">
                  {data.allSanityPost.group.map(cat => (
                    <li key={cat.fieldValue} className="list-inline-item my-2">
                      <Link
                        to={slugify(cat.fieldValue.toLowerCase())}
                        className="px-3 py-2 bg-light rounded-sm"
                      >
                        {cat.fieldValue}
                      </Link>
                    </li>
                  ))}
                  {/*placeholder categories - REMOVE LATER*/}
                  <li className="list-inline-item my-2">
                    <Link to="/" className="px-3 py-2 bg-light rounded-sm">
                      Automation
                    </Link>
                  </li>
                  <li className="list-inline-item my-2">
                    <Link to="/" className="px-3 py-2 bg-light rounded-sm">
                      Beam
                    </Link>
                  </li>
                  <li className="list-inline-item my-2">
                    <Link to="/" className="px-3 py-2 bg-light rounded-sm">
                      Coping
                    </Link>
                  </li>
                  <li className="list-inline-item my-2">
                    <Link to="/" className="px-3 py-2 bg-light rounded-sm">
                      Cutting
                    </Link>
                  </li>
                  <li className="list-inline-item my-2">
                    <Link to="/" className="px-3 py-2 bg-light rounded-sm">
                      Drilling
                    </Link>
                  </li>
                  <li className="list-inline-item my-2">
                    <Link to="/" className="px-3 py-2 bg-light rounded-sm">
                      Fabrication
                    </Link>
                  </li>
                  <li className="list-inline-item my-2">
                    <Link to="/" className="px-3 py-2 bg-light rounded-sm">
                      Flat and Angle
                    </Link>
                  </li>
                  <li className="list-inline-item my-2">
                    <Link to="/" className="px-3 py-2 bg-light rounded-sm">
                      Manufacturing
                    </Link>
                  </li>
                  <li className="list-inline-item my-2">
                    <Link to="/" className="px-3 py-2 bg-light rounded-sm">
                      Milling
                    </Link>
                  </li>
                  <li className="list-inline-item my-2">
                    <Link to="/" className="px-3 py-2 bg-light rounded-sm">
                      MSI
                    </Link>
                  </li>
                  <li className="list-inline-item my-2">
                    <Link to="/" className="px-3 py-2 bg-light rounded-sm">
                      Oxy-fuel
                    </Link>
                  </li>
                  <li className="list-inline-item my-2">
                    <Link to="/" className="px-3 py-2 bg-light rounded-sm">
                      Plasma
                    </Link>
                  </li>
                  <li className="list-inline-item my-2">
                    <Link to="/" className="px-3 py-2 bg-light rounded-sm">
                      Plate
                    </Link>
                  </li>
                  <li className="list-inline-item my-2">
                    <Link to="/" className="px-3 py-2 bg-light rounded-sm">
                      Punching
                    </Link>
                  </li>
                  <li className="list-inline-item my-2">
                    <Link to="/" className="px-3 py-2 bg-light rounded-sm">
                      Quality
                    </Link>
                  </li>
                  <li className="list-inline-item my-2">
                    <Link to="/" className="px-3 py-2 bg-light rounded-sm">
                      Sawing
                    </Link>
                  </li>

                  <li className="list-inline-item my-2">
                    <Link to="/" className="px-3 py-2 bg-light rounded-sm">
                      Shearing
                    </Link>
                  </li>
                  <li className="list-inline-item my-2">
                    <Link to="/" className="px-3 py-2 bg-light rounded-sm">
                      Shot Blasting
                    </Link>
                  </li>
                  <li className="list-inline-item my-2">
                    <Link to="/" className="px-3 py-2 bg-light rounded-sm">
                      Software
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Blogs

export const blogQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityPost {
      edges {
        node {
          id
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          excerpt
          slug {
            current
          }
          categories {
            title
          }
        }
      }
      group(field: categories___title) {
        fieldValue
      }
    }
  }
`
